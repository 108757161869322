import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";
import mediSystem from "../../Assets/Projects/mediSystem.jpg";
import motorrad from "../../Assets/Projects/bmw_motorrad.PNG";
import monamialbert from "../../Assets/Projects/ami_albert.png";
import facebookFetcher from "../../Assets/Projects/graph_facebook.png";
import Tools from "../../Assets/Projects/python_tool.PNG";
import bitsOfCode from "../../Assets/Projects/blog.png";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          My Recent <strong className="purple">Works </strong>
        </h1>
        <p style={{ color: "white" }}>
          Here are a few projects I've worked on recently.
        </p>
        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={facebookFetcher}
              isBlog={false}
              title="Automatic Facebook Fetcher"
              description="Facebook service that fetch data from Graph API , and update feeds into Real time database every minute."
              link="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={bitsOfCode}
              isBlog={false}
              title="AI Whatsapp Chatbot"
              description="An automated AI for az restaurant , it starts with greeting the client,presents the menu then calculate order total."
              link="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={monamialbert}
              isBlog={false}
              title="Mon ami Albert"
              description="E-Learning plateform that allows you to consult the courses / quizzes and gaing points (React , Firebase)"
              link="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={mediSystem}
              isBlog={false}
              title="Medisystem"
              description="Used for the overall management of a hospital"
              link="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={Tools}
              isBlog={false}
              title="Tools for Unreal"
              description="Using 'Pyhton scripting and Blueprint'this is a tool for the detection of unused assets in the whole project and possibility to delete them ."
              link="#"
            />
          </Col>

          <Col md={4} className="project-card">
            <ProjectCard
              imgPath={motorrad}
              isBlog={false}
              title="bmw-motorrad.ma"
              description="BMW Motorrad is the motorcycle brand of BMW, part of its Corporate and Brand Development division."
              link="https://www.bmw-motorrad.ma/"
            />
          </Col>
        </Row>
      </Container>
    </Container>
  );
}

export default Projects;
